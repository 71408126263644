import axios, { CancelToken } from 'axios';
import config from '../config';
import {
  InstancePublicDataType,
  InstancePublicDataListResponseType,
  HardwareFavoriteDataType,
  HardwareFavoriteResponseType,
  InstancesExtendedPublicDataListResponseType,
  nicknameType,
  nicknameLogs, ListInstancePublicDataType,
} from '../types/appTypes';
import {
  FloorsResponseType,
  HardwareActionCommonRequestType,
  HardwareActionWithFloorRequestType,
  HardwareReadersResponseType,
  HardwareReadersStatusesResponseType,
  HardwareActionResponseType,
  HardwareDownstreamResponseType,
  HardwareControllersResponseType,
  ScheduleActionsResponseType,
  SchedulesTaskIDsListType,
  HardwareActionsListResponse,
  HardwareReaderLogsListResponseType,
  ScheduleTaskIDType,
  readersTaskIdType,
} from '../types/readersTypes';
import { ScheduleResponseType, SetSchedulesRequestType } from '../types/schedulesTypes';
import { BuildingDataType, ServerCommonDataType } from '../types/buildingInfoTypes';
import { UserLogsRequestType, UserLogsResponseType } from '../types/userLogTypes';
import { DevicesDataListResponse } from '../types/deviceInfoTypes';
import {
  HardwareUserActionsListResponseType,
  HardwareUserBodyType,
  HardwareUserDataType,
  ServersListResponseType,
} from '../types/HardwareUsersTypes';
import { UserPublicDataType } from '../types/authTypes';
import {
  FloorGroupAdditionalDataResponseType,
  FloorGroupForUpdateType,
  FloorGroupFullDataType,
  FloorGroupMainDataType
} from '../types/elevatorsTypes';
import {
  AnalyticActionResponseListType,
  AnalyticDataType,
  AnalyticParams,
  BackendVersionType
} from '../types/types';
import {
  SysLogsRequestType,
  SysLogsResponseType,
} from '../types/sysLogTypes';
import {
  CreateInstanceGroupRequestType,
  InstanceGroupDetailResponseType,
  InstanceGroupHardwareReadersListResponse,
  InstanceGroupListType,
  InstanceGroupMassActionsRequest,
  InstanceGroupPagination,
  InstanceGroupScheduleRequestType,
  InstanceGroupsSchedulesTaskIdsList,
  InstanceGroupTaskIdList, InstanceSelectData,
  ScheduleResponseInstanceGroups
} from '../types/instancesGroupsTypes';
import {
  AccessLevelListRes,
  AccessLevelRes,
  CountingAreaItemRes,
  CountingAreaRulesRes
} from '../types/countingAreasTypes';
import { MaxPeopleCount } from '../components/modules/CountingAreas/ModalForCSV/ModalForCSV';
import {
  HolidayGroupRequest, HolidayGroupResponse,
  Holidays,
  HolidaysMutationResponse,
  HolidaysQueryResponse
} from '../types/holidaysTypes';
import { InstanceLogData, InstanceLogsFilter } from '../types/instanceLogTypes';

class ApiInstanceClass {
  axiosInstance = axios.create({});

  createAxiosInstance(token: string) {
    this.axiosInstance = axios.create({
      withCredentials: true,
      baseURL: config.apiUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getAxiosInstance() {
    return this.axiosInstance;
  }
}

export const ApiInstance = new ApiInstanceClass();

export const weworkAPI = {

  //== Version app region

  getBackendVersion(): Promise<BackendVersionType> {
    return ApiInstance.getAxiosInstance()
      .get<BackendVersionType>('version')
      .then((r) => r.data);
  },

  //=== Auth routes region

  getUserInfo() {
    return ApiInstance.getAxiosInstance()
      .get<UserPublicDataType>('user/info')
      .then((r) => r.data);
  },

  //=== Common routes region

  getBuildingInfo(ip_address: string, instance: string, server: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<BuildingDataType>('building_info', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getServerInfo(ip_address: string, instance: string, server: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<ServerCommonDataType>('server_info', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getCamerasInfo(ip_address: string, instance: string, server: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<DevicesDataListResponse>('cameras', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  // getCamerasStatuses(ip_address: string, macList: string[]) {
  //   return ApiInstance.getAxiosInstance()
  //     .post<CamerasStatusDataListResponse>('cameras/statuses', macList, {
  //       params: {
  //         ip_address,
  //       },
  //     })
  //     .then((r) => r.data);
  // },
  getIntercomsInfo(ip_address: string, instance: string, server: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<DevicesDataListResponse>('intercoms', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },

  //=== Hardware routes region

  getControllers(ip_address: string, instance: string, server: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<HardwareControllersResponseType>('controllers', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  postControllerReboot(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    payload: HardwareActionCommonRequestType,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HardwareActionResponseType>('controller/reboot', payload, {
        params: {
          instance,
          ip_address,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getDownstream(ip_address: string, instance: string, server: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<HardwareDownstreamResponseType>('downstream', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getReaders(ip_address: string, instance: string, server: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<HardwareReadersResponseType>('readers', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getReadersStatuses(ip_address: string, instance: string, server: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<HardwareReadersStatusesResponseType>('readers/statuses', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getReaderLogs(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    reader_key: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .get<HardwareReaderLogsListResponseType>('reader/get_logs', {
        params: {
          ip_address,
          instance,
          server,
          platform,
          reader_key,
        },
      })
      .then((r) => r.data);
  },
  postAddNickname(
    payload: nicknameType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HardwareFavoriteResponseType>('/hardware/add_nickname', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getNicknameLogs(
    hardware_id: string,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .get<nicknameLogs[]>('/hardware/get_nickname_logs', {
        params: {
          hardware_id,
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  postChangeNickname(
    payload: nicknameType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HardwareFavoriteResponseType>('/hardware/change_nickname', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  postResetReader(
    payload: HardwareActionCommonRequestType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HardwareActionResponseType>('reader/reset', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  postUnlockReader(
    payload: HardwareActionCommonRequestType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HardwareActionResponseType>('reader/unlock', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  postLockReader(
    payload: HardwareActionCommonRequestType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HardwareActionResponseType>('reader/lock', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  postPulseReader(
    payload: HardwareActionWithFloorRequestType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HardwareActionResponseType>('reader/pulse', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getLockAllReader(ip_address: string, instance: string, server: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<readersTaskIdType>('readers/lock_all', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getResetAllReader(ip_address: string, instance: string, server: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<readersTaskIdType>('readers/reset_all', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getFloors(ip_address: string, instance_id: string, server_id: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<FloorsResponseType>('floors', {
        params: {
          ip_address,
          instance_id,
          server_id,
          platform,
        },
      })
      .then((r) => r.data);
  },
  addFavorite(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    data: HardwareFavoriteDataType,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HardwareFavoriteResponseType>('/hardware/add_favorite', data, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  removeFavorite(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    data: HardwareFavoriteDataType,
  ) {
    return ApiInstance.getAxiosInstance()
      .delete<HardwareFavoriteResponseType>('/hardware/remove_favorite', {
        data,
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },

  getFloorGroupAdditionalData(
    ip_address: string,
    instance_id: string,
    server_id: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .get<FloorGroupAdditionalDataResponseType>('get_floor_data', {
        params: {
          ip_address,
          instance_id,
          server_id,
          platform,
        },
      })
      .then((r) => r.data);
  },

  getFloorGroups(ip_address: string, instance_id: string, server_id: number, platform: string) {
    return ApiInstance.getAxiosInstance()
      .get<FloorGroupMainDataType[]>('get_floor_groups', {
        params: {
          ip_address,
          instance_id,
          server_id,
          platform,
        },
      })
      .then((r) => r.data);
  },

  getFloorGroupById(
    ip_address: string,
    instance_id: string,
    server_id: number,
    platform: string,
    id: number,
  ) {
    return ApiInstance.getAxiosInstance()
      .get<FloorGroupFullDataType>(`get_floor_group/${id}/`, {
        params: {
          ip_address,
          instance_id,
          server_id,
          platform,
        },
      })
      .then((r) => r.data);
  },
  updateFloorGroup(
    payload: FloorGroupForUpdateType,
    ip_address: string,
    instance_id: string,
    server_id: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<FloorGroupFullDataType>('/update_floor_group', payload, {
        params: {
          ip_address,
          instance_id,
          server_id,
          platform,
        },
      })
      .then((r) => r.data);
  },

  //=== User Logs routes region

  getUserLogs(
    userLogsPayload: UserLogsRequestType,
    ip_address: string,
    instance?: string,
    server?: number,
    platform?: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<UserLogsResponseType>('logs/get_user_logs', userLogsPayload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },

  //=== Sys Logs routes region

  getSysLogs(
    sysLogsPayload: SysLogsRequestType,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<SysLogsResponseType>('sys_logs/get_sys_logs', sysLogsPayload)
      .then((r) => r.data);
  },

  //=== Schedule routes region

  getSchedulesData(ip_address: string, instance: string, server: number, platform: string) {
    //need for return all schedules list
    return ApiInstance.getAxiosInstance()
      .get<ScheduleResponseType>('schedule/get_schedules', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getScheduleDataForMassAction(
    payload: readersTaskIdType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HardwareActionsListResponse>('/schedule/get_readers_action_mass_response', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  postSetSchedules(
    payload: SetSchedulesRequestType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    //need for create schedules by schedule list
    return ApiInstance.getAxiosInstance()
      .post<ScheduleActionsResponseType>('schedule/set_schedules', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  deleteSchedules(
    payload: SchedulesTaskIDsListType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    //need for delete schedules by task list
    return ApiInstance.getAxiosInstance()
      .post<ScheduleActionsResponseType>('schedule/delete_schedules', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  deleteCurrentSchedules(
    payload: SchedulesTaskIDsListType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    //need for delete current schedules by task list
    return ApiInstance.getAxiosInstance()
      .post<ScheduleActionsResponseType>('schedule/delete_current_schedules', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  deleteCurrentSchedule(
    payload: ScheduleTaskIDType,
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    //need for delete the closest schedule
    return ApiInstance.getAxiosInstance()
      .post<ScheduleActionsResponseType>('schedule/delete_current_schedule', payload, {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },

  //=== Servers routes region

  getAllInstancesList() {
    //need for get all list of instances
    return ApiInstance.getAxiosInstance()
      .get<InstancePublicDataListResponseType>('server/all_instances')
      .then((r) => r.data);
  },
  getCurrentInstance(ip_address: string) {
    //need for receive current instance by ip
    return ApiInstance.getAxiosInstance()
      .get<ListInstancePublicDataType>('server/instance', {
        params: {
          ip_address,
        },
      })
      .then((r) => r.data);
  },
  getAllInstancesExtendedPublicData() {
    //need for get all list of instances
    return ApiInstance.getAxiosInstance()
      .get<InstancesExtendedPublicDataListResponseType>('server/all_instances_extended_public_data')
      .then((r) => r.data);
  },

  //=== Hardware User region

  getHardwareServers() {
    // TODO description
    return ApiInstance.getAxiosInstance()
      .get<ServersListResponseType>('hardware_user/servers')
      .then((r) => r.data);
  },
  getHardwareUserByUUID(user_uuid: string) {
    // TODO description
    return ApiInstance.getAxiosInstance()
      .get<HardwareUserDataType>('hardware_user', {
        params: {
          user_uuid,
        },
      })
      .then((r) => r.data);
  },
  obliterateHardwareUser(payload: HardwareUserBodyType) {
    // TODO description
    return ApiInstance.getAxiosInstance()
      .post<HardwareUserActionsListResponseType>('hardware_user/obliterate', payload)
      .then((r) => r.data);
  },

  //=== Analytics

  setVisit(
    ip_address: string,
    instance: string,
    server: number,
    platform: string
  ) {
    // TODO description
    return ApiInstance.getAxiosInstance()
      .get<string>('/analytic/add_visit', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
      })
      .then((r) => r.data);
  },
  getAnalytics(params: AnalyticParams) {
    // TODO description
    return ApiInstance.getAxiosInstance()
      .get<AnalyticDataType>('/analytic/get_analytics', {
        params: params,
      })
      .then((r) => r.data);
  },
  getActionAnalytics(params: AnalyticParams) {
    // TODO description
    return ApiInstance.getAxiosInstance()
      .get<AnalyticActionResponseListType>('/analytic/get_action_analytics', {
        params: params,
      })
      .then((r) => r.data);
  },
  getAnnualAnalytic(params: AnalyticParams) {
    return ApiInstance.getAxiosInstance()
      .get('/analytic/get_annual_actions_analytics', {
        params: params,
      })
      .then((r) => r.data);
  },

  //=== Instances Groups
  getInstancesGroupList( data?: InstanceGroupPagination){
    return ApiInstance.getAxiosInstance()
      .post<InstanceGroupListType>('/instance_groups/get_instance_group_list', data)
      .then((r) => r.data);
  },
  createInstanceGroup(payload: CreateInstanceGroupRequestType){
    return ApiInstance.getAxiosInstance()
      .post<InstanceGroupDetailResponseType>('/instance_groups/create_instance_group', payload)
      .then((r) => r.data);
  },
  updateInstanceGroup(payload: CreateInstanceGroupRequestType){
    return ApiInstance.getAxiosInstance()
      .put<InstanceGroupDetailResponseType>('/instance_groups/update_instance_group', payload)
      .then((r) => r.data);
  },
  getInstanceGroupById(id: number) {
    return ApiInstance.getAxiosInstance()
      .get<InstanceGroupDetailResponseType>(`/instance_groups/get_instance_group_by_id/${id}`)
      .then((r) => r.data);
  },
  deleteInstanceGroup(id: number) {
    return ApiInstance.getAxiosInstance()
      .delete(`/instance_groups/delete_instance_group_by_id/${id}`)
      .then((r) => r.data);
  },
  makeInstanceGroupFavorite(id: number) {
    return ApiInstance.getAxiosInstance()
      .post(`/instance_groups/instance_group_add_favorite/${id}`, id)
      .then((r) => r.data);
  },
  deleteInstanceGroupFavorite(id: number) {
    return ApiInstance.getAxiosInstance()
      .delete(`/instance_groups/instance_group_delete_favorite/${id}`)
      .then((r) => r.data);
  },
  resetAllInstancesGroup(id: number, instances_list?: InstanceGroupMassActionsRequest){
    return ApiInstance.getAxiosInstance()
      .post<InstanceGroupTaskIdList>(`/instance_groups/${id}/readers/reset_all`, instances_list)
      .then((r) => r.data);
  },
  lockAllInstancesGroup(id: number, instances_list?: InstanceGroupMassActionsRequest){
    return ApiInstance.getAxiosInstance()
      .post(`/instance_groups/${id}/readers/lock_all`, instances_list)
      .then((r) => r.data);
  },
  setSchedulesInstancesGroup(id: number, payload: InstanceGroupScheduleRequestType){
    return ApiInstance.getAxiosInstance()
      .post<readersTaskIdType>(`/instance_groups/${id}/set_schedules`, payload)
      .then((r) => r.data);
  },
  getSchedulesInstanceGroup(id:number){
    return ApiInstance.getAxiosInstance()
      .get<ScheduleResponseInstanceGroups>(`/instance_groups/${id}/get_schedules`)
      .then((r) => r.data);
  },
  deleteCurrentScheduleInstanceGroup(id: number,  data: InstanceGroupsSchedulesTaskIdsList){
    return ApiInstance.getAxiosInstance()
      .post(`/instance_groups/${id}/delete_current_schedules`, data)
      .then((r) => r.data);
  },
  getReadersByInstanceGroup(id: number){
    return ApiInstance.getAxiosInstance()
      .get<InstanceGroupHardwareReadersListResponse>(`/instance_groups/${id}/readers`)
      .then((r) => r.data);
  },
  getCountingAreas(
    ip_address: string,
    instance: string,
    server: number, platform: string,
    cancelToken?: CancelToken) {
    return ApiInstance.getAxiosInstance()
      .get<CountingAreaItemRes>('/event_monitoring/counting_areas', {
        params: {
          ip_address,
          instance,
          server,
          platform,
        },
        cancelToken,
      })
      .then((r) => r.data);
  },
  getCountingAreaRules(
    ip_address: string, instance: string, server: number, platform: string, counting_area_id: string
  ) {
    return ApiInstance.getAxiosInstance()
      .get<CountingAreaRulesRes>(`/event_monitoring/counting_areas/${counting_area_id}/rules`, {
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  setAccessLevelForCountinfArea(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    counting_area_id: string,
    access_level_id: string
  ) {
    return ApiInstance.getAxiosInstance()
      .post<AccessLevelRes>(`/event_monitoring/counting_areas/${counting_area_id}/access_level`,
        {
          access_level_id
        },
        {
          params: {
            ip_address,
            instance,
            server,
            platform
          },
        })
      .then((r) => r.data);
  },
  getAccessLevels(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    cancelToken?: CancelToken
  ) {
    return ApiInstance.getAxiosInstance()
      .get<AccessLevelListRes>('/accesslevels', {
        params: {
          ip_address,
          instance,
          server,
          platform
        },
        cancelToken
      })
      .then((r) => r.data);
  },
  setAccessLevelStatus(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    access_level_id: string,
    level_status: string,

  ) {
    return ApiInstance.getAxiosInstance()
      .put<string>(`/accesslevels/${access_level_id}`, { action: level_status },{
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  deleteCountingAreaAccessLevel(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    counting_aria_id: string,

  ) {
    return ApiInstance.getAxiosInstance()
      .delete<string>(`/event_monitoring/counting_areas/${counting_aria_id}/access_level`,{
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  downloadFileCSV(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    counting_area_id: string,
    data:{start: number, end: number, step: string}
  ) {
    return ApiInstance.getAxiosInstance()
      .post<File>(`/event_monitoring/counting_areas/${counting_area_id}/csv`, data,{
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  getCountingAreaMaxCount(
    ip_address: string, instance: string, server: number, platform: string, counting_area_id: string
  ) {
    return ApiInstance.getAxiosInstance()
      .get<MaxPeopleCount[]>(`/event_monitoring/counting_areas/${counting_area_id}/max_count`, {
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  getHolidaysData(
    ip_address: string, instance: string, server: number, platform: string
  ) {
    return ApiInstance.getAxiosInstance()
      .get<HolidaysQueryResponse>('/holidays', {
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  addHolidaysData(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    holiday: Holidays

  ) {
    return ApiInstance.getAxiosInstance()
      .post<HolidaysMutationResponse>('/holidays', holiday,{
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  updateHolidaysData(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    holiday: Holidays

  ) {
    return ApiInstance.getAxiosInstance()
      .put<HolidaysMutationResponse>(`/holidays/${holiday.holiday_id}`,holiday, {
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  deleteHolidaysData(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    holiday_key: string
  ) {
    return ApiInstance.getAxiosInstance()
      .delete<HolidaysMutationResponse>(`/holidays/${holiday_key}`, {
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  getHolidaysGroupsData(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
  ) {
    return ApiInstance.getAxiosInstance()
      .get<string[]>('/holidays/groups', {
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  addHolidaysToGroupData(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    holiday: HolidayGroupRequest,
    group_id: number,
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HolidaysMutationResponse>(`/instance_groups/${group_id}/holiday`, holiday,{
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  getInstanceLogs(
    ip_address: string,
    instance: string,
    server: number,
    platform: string,
    data?: InstanceLogsFilter
  ) {
    return ApiInstance.getAxiosInstance()
      .post<InstanceLogData[]>('/logs', data, {
        params: {
          ip_address,
          instance,
          server,
          platform
        },
      })
      .then((r) => r.data);
  },
  getGroupHolidaysData(
    group_id: number,
    instances: InstanceSelectData[]
  ) {
    return ApiInstance.getAxiosInstance()
      .post<HolidayGroupResponse>(`/instance_groups/${group_id}/holidays`,
        instances)
      .then((r) => r.data);
  },
};
